import styled from 'styled-components';
import Bg from '../../../static/bg-pattern.svg';

const withBackgroundPattern = styled.div`
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Bg});
    background-size: cover;
    opacity: 0.1;
    pointer-events: none;
  }
`;

export default withBackgroundPattern;
