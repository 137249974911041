import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Heading from './typography/Heading';
import Text from './typography/Text';
import ArrowLink from './Link';

const StyledCard = styled.div`
  position: relative;
  border: 1px solid #e6e5e5;
  padding: 3rem 3rem 7rem;
  text-align: center;
  margin-bottom: 40px;

  a {
    display: inline-block;
    margin: 3rem 0 1rem;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 8rem;
  transform: translate(-50%, 50%);
  height: 8rem;
  background: whitesmoke;
  border-radius: 50%;

  span {
    display: flex;
  }
`;

const Card = ({ title, text, icon, uid }) => {
  return (
    <StyledCard>
      <Heading as="h2">{title}</Heading>
      <Text>{text.substr(0, 130)}...</Text>
      <ArrowLink to={`/diensten/${uid}`} green={1}>
        Lees verder
      </ArrowLink>
      <Icon>
        <SVG src={icon} />
      </Icon>
    </StyledCard>
  );
};

export default Card;
