import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const StyledButton = styled.a`
  display: inline-block;
  padding: 1rem 1.75rem;
  background: ${({ theme }) => theme.primaryColor};
  color: white;
  border-radius: 3px;
  border: none;
  border-bottom: 2px solid ${({ theme }) => darken(0.2, theme.primaryColor)};
  margin: 1.5rem 0;
  transition: background 0.25s;

  &:hover {
    background: ${({ theme }) => darken(0.05, theme.primaryColor)};
  }
`;

const Button = ({ title, link }) => {
  return <StyledButton href={link}>{title}</StyledButton>;
};

export default Button;
