import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Cta from '../components/Cta';
import SEO from '../components/Seo';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Cards from '../components/Cards';
import Slider from '../components/Slider';
import TextAndImage from '../components/TextAndImage';

const homepage = ({ data }) => {
  const {
    hero_title,
    hero_img,
    hero_introductie,
    body,
  } = data.allPrismicHomepage.edges[0].node.data;

  const matchComponent = slice => {
    switch (slice.slice_type) {
      case 'tekst_en_foto':
        return (
          <TextAndImage
            key={slice.id}
            title={slice.primary.textandimage_title.text}
            text={slice.primary.textandimage_text.html}
            link={slice.primary.link && slice.primary.link.document[0].uid}
            linkTitle={
              slice.primary.link_title && slice.primary.link_title.text
            }
            imgFirst={slice.primary.position1 === 'Links'}
            img={slice.primary.textandimage_img.localFile.childImageSharp.fluid}
          />
        );
      case 'tekst_en_icoon':
        return <Cards key={slice.id} items={slice.items} />;
      case 'projecten_slider':
        return (
          <Slider
            key={slice.id}
            title={slice.primary.slider_title.text}
            items={slice.items}
          />
        );
      default:
      // nada
    }
  };

  return (
    <Layout>
      <SEO title="Homepage" />
      <Hero img={hero_img.localFile.childImageSharp.fluid} />
      <Container>
        <Intro title={hero_title.text} text={hero_introductie.text} />
        {body.map(slice => matchComponent(slice))}
      </Container>
      <Cta />
    </Layout>
  );
};

export default homepage;

export const pageQuery = graphql`
  query homepage {
    allPrismicHomepage {
      edges {
        node {
          id
          data {
            hero_title {
              text
            }
            hero_introductie {
              text
            }
            hero_img {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80, maxHeight: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            body {
              ... on PrismicHomepageBodyTekstEnFoto {
                id
                slice_type
                primary {
                  position1
                  textandimage_title {
                    text
                  }
                  textandimage_text {
                    html
                  }
                  textandimage_img {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 600, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  link {
                    id
                    document {
                      uid
                    }
                  }
                  link_title {
                    text
                  }
                }
              }
              ... on PrismicHomepageBodyTekstEnIcoon {
                id
                slice_type
                items {
                  diensten {
                    document {
                      uid
                      data {
                        icon {
                          url
                        }
                        tekst {
                          text
                        }
                        titel {
                          text
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicHomepageBodyProjectenSlider {
                id
                slice_type
                primary {
                  slider_title {
                    text
                  }
                }
                items {
                  projecten {
                    id
                    uid
                    document {
                      data {
                        datum1 {
                          html
                        }
                        text {
                          html
                        }
                        title {
                          text
                        }
                        img {
                          localFile {
                            childImageSharp {
                              fixed(height: 415, quality: 80) {
                                ...GatsbyImageSharpFixed_withWebp
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
