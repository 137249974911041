import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import ComponentWrapper from './ComponentWrapper';
import Card from './Card';

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 3rem;
`;

const Cards = ({ items }) => {
  return (
    <ComponentWrapper>
      <Wrapper>
        {items.map((item, i) => {
          const { uid } = item.diensten.document[0];
          const { titel, tekst, icon } = item.diensten.document[0].data;
          return (
            <ScrollAnimation
              key={titel.text}
              animateIn="fadeInUp"
              delay={i * 75}
              animateOnce
            >
              <Card
                title={titel.text}
                icon={icon.url}
                text={tekst.text}
                uid={uid}
              />
            </ScrollAnimation>
          );
        })}
      </Wrapper>
    </ComponentWrapper>
  );
};

export default Cards;
