import React, { useState } from 'react';
import { Link } from 'gatsby';
import Swiper from 'react-id-swiper';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import ScrollAnimation from 'react-animate-on-scroll';
import ComponentWrapper from './ComponentWrapper';
import Heading from './typography/Heading';
import withBackgroundPattern from './helpers/withBackgroundPattern';
import ArrowLink from './Link';
import Arrow from '../../static/arrow.svg';

const SliderWrapper = styled.div`
  position: relative;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    height: 415px;
  }
`;

const SlideWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.primaryColorDark};

  @media (${({ theme }) => theme.respondTo.desktop}) {
    flex-wrap: nowrap;
    min-height: 415px;
  }
`;

const SlideImg = styled.div`
  width: 100%;
  display: flex;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 60%;
  }

  .gatsby-image-wrapper {
    max-height: 200px;
    min-height: 100%;
    min-width: 100%;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      max-height: auto;
    }

    img {
      display: block;
    }
  }
`;

const SlideContent = styled(withBackgroundPattern)`
  position: relative;
  padding: 2.5rem;
  color: white;
  width: 100%;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 5rem;
    width: 40%;
  }

  > * {
    z-index: 1;
  }
`;

const SliderNav = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const SliderNavItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.primaryColor};
  appearance: none;
  padding: 0;
  width: 6.4rem;
  height: 6.4rem;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;

  &:first-of-type {
    border-right: 1px solid ${({ theme }) => theme.primaryColorDark};
  }

  &:last-of-type {
    transform: scaleX(-1);
  }
`;

const Slider = ({ title, items }) => {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const settings = {
    loop: true,
  };

  return (
    <ComponentWrapper>
      <ScrollAnimation animateIn="fadeInUp" animateOnce>
        <Heading withTrademark as="h2">
          {title}
        </Heading>
        <SliderWrapper>
          <Swiper getSwiper={updateSwiper} {...settings}>
            {items.map((item, i) => {
              const { uid } = item.projecten;
              const {
                datum1,
                text,
                title,
                img,
              } = item.projecten.document[0].data;
              return (
                <div key={title + i}>
                  <SlideWrapper>
                    <SlideImg>
                      <Img fixed={img.localFile.childImageSharp.fixed} />
                    </SlideImg>
                    <SlideContent>
                      <Heading white>
                        <Link to={`/projecten/${uid}`}>{title.text}</Link>
                      </Heading>
                      <p>{datum1.text}</p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: text.html.slice(0, 275) + '...',
                        }}
                      />
                      <ArrowLink to={`/projecten/${uid}`} white={1}>
                        Lees verder
                      </ArrowLink>
                    </SlideContent>
                  </SlideWrapper>
                </div>
              );
            })}
          </Swiper>
          <SliderNav>
            <SliderNavItem onClick={goPrev}>
              <img alt="Vorige slide" src={Arrow} />
            </SliderNavItem>
            <SliderNavItem onClick={goNext}>
              <img alt="Volgende slide" src={Arrow} />
            </SliderNavItem>
          </SliderNav>
        </SliderWrapper>
      </ScrollAnimation>
    </ComponentWrapper>
  );
};

export default Slider;
