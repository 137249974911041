import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import ScrollAnimation from 'react-animate-on-scroll';
import Heading from './typography/Heading';
import ComponentWrapper from './ComponentWrapper';
import Button from './Button';

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Content = styled.div`
  flex: 1;
  margin: 2rem 0;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: ${({ imgFirst }) => (imgFirst ? '0 0 0 12rem' : '0 12rem 0 0')};
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  order: ${({ imgFirst }) => (imgFirst ? '-1' : '')};
  @media (${({ theme }) => theme.respondTo.desktop}) {
    max-width: 45rem;
  }
`;

const TextAndImage = ({ title, text, img, imgFirst, link, linkTitle }) => {
  return (
    <ComponentWrapper>
      <Wrapper>
        <Content imgFirst={imgFirst}>
          <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <Heading withTrademark as="h2">
              {title}
            </Heading>
            <div dangerouslySetInnerHTML={{ __html: text }} />
            {link && <Button link={link} title={linkTitle} />}
          </ScrollAnimation>
        </Content>
        <ImgWrapper imgFirst={imgFirst}>
          <ScrollAnimation animateIn="fadeInUp" delay={200} animateOnce>
            <Img fluid={img} />
          </ScrollAnimation>
        </ImgWrapper>
      </Wrapper>
    </ComponentWrapper>
  );
};

export default TextAndImage;
