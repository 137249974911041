import React from 'react';
import styled from 'styled-components';
import ComponentWrapper from './ComponentWrapper';
import Container from './Container';
import Heading from './typography/Heading';
import Text from './typography/Text';

const CtaWrapper = styled.section`
  background: ${({ theme }) => theme.lightGrey};
  text-align: center;
  padding: 5rem 0;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding: 4.8rem 0;
  }
`;

const TextWrapper = styled.div`
  padding: 1.25rem 0;
`;

const CtaContent = styled.div`
  max-width: 65rem;
  margin: 0 auto;
`;

const Cta = () => {
  return (
    <ComponentWrapper noMargin>
      <CtaWrapper>
        <Container>
          <CtaContent>
            <Heading withTrademark centered as="h2">
              Mogelijkheden?
            </Heading>
            <TextWrapper>
              <Text>
                Benieuwd wat ik voor u kan betekenen?
                <br />
                Neem dan vrijblijvend contact met mij op via{' '}
                <a href="tel:0031683305091">06-83305091</a> of stuur een e-mail
                naar{' '}
                <a href="mailto:info@planenplant.nl">info@planenplant.nl</a>.
              </Text>
            </TextWrapper>
          </CtaContent>
        </Container>
      </CtaWrapper>
    </ComponentWrapper>
  );
};

export default Cta;
